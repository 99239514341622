import React from 'react';
import Meta from '../components/Meta';
import ServicesComp from '../components/ServicesComp';

const ServicesCompPage = () => {
    return (
        <div>
            <Meta title={'Servicios'} />
            <header>
                <ServicesComp />
            </header>
        </div>
    );
};

export default ServicesCompPage;