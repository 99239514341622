import '../styles/Services.css'

const ServicesComp = () => {
    return (
        <body>

            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css"></link>

            <div id="generic_price_table">
                <div className="breadcrumbs breadcrums-div" data-aos="fade-in">
                    <div className="container">
                        <h2>Nuestros servicios complementarios y beneficios adicionales</h2>
                        <p className='text-center'>Conoce un poco más sobre nuestros servicios, estaremos contigo en los momentos más difíciles.</p>
                    </div>
                </div>

                <div className="main_container container-xxl slide d-flex justify-content-evenly align-items-center">
                    <div className="image__container_mas img-fluid">
                        <img src="./img/homeSliderSection/dogandcat.jpg" alt="Funeral home somos todos" />
                    </div>
                    <div className="slide__info col-5">
                        <h2>Mascotas</h2>
                        <p>En Funeral Home, entendemos lo importante que son tus mascotas. Ofrecemos servicios funerarios, para que puedas despedirte de tu fiel compañero con amor. Estamos aquí para ayudarte en esos momentos difíciles  </p>
                    </div>
                </div>
                <section>

                    <div className="container">


                        <div className="row d-flex justify-content-center">
                            {/*<div className="col-md-4">


                                <div className="generic_content clearfix">


                                    <div className="generic_head_price clearfix">


                                        <div className="generic_head_content clearfix">


                                            <div className="head_bg"></div>
                                            <div className="head">
                                                <span>Básico</span>
                                            </div>


                                        </div>



                                        <div className="generic_price_tag clearfix">
                                            <span className="price">
                                                <span className="sign">$</span>
                                                <span className="currency">21.000</span>
                                                <span className="cent">.00</span>
                                                <span className="month">/MES</span>
                                            </span>
                                        </div>


                                    </div>



                                    <div className="generic_feature_list">
                                        <ul>
                                            <li><i className="bi bi-check2-circle"> </i>Traslado nacional del cuerpo sin límite de kilometraje</li>
                                            <li><i className="bi bi-check2-circle"> </i>Transporte para acompañantes (donde se requiera) bus o buseta</li>
                                            <li><i className="bi bi-check2-circle"> </i>Bóveda o cremación (cementerio Distrital, Local o Municipal)</li>
                                            <li><i className="bi bi-check2-circle"> </i>Urna para cenizas (para los servicios de cremación)</li>
                                            <li><i className="bi bi-check2-circle"> </i>Tramites de rigor</li>
                                            <li><i className="bi bi-check2-circle"> </i>Preservación de cuerpo</li>
                                            <li><i className="bi bi-check2-circle"> </i>Cofre tipo plan</li>
                                            <li><i className="bi bi-check2-circle"> </i>Servicio de cafetería</li>
                                            <li><i className="bi bi-check2-circle"> </i>Ofrenda floral</li>
                                            <li><i className="bi bi-check2-circle"> </i>Carteles</li>
                                            <li><i className="bi bi-check2-circle"> </i>Coche fúnebre</li>
                                            <li><i className="bi bi-check2-circle"> </i>Ceremonia religiosa</li>
                                            <li><i className="bi bi-check2-circle"> </i>Pergamino recordatorio</li>
                                        </ul>
                                    </div>



                                    <div className="generic_price_btn clearfix">
                                        <a className="" href="https://wa.link/knj1ke">Info <i className="bi bi-arrow-right-short"></i></a>
                                    </div>


                                </div>


                            </div>*/}

                            <div className="col-md-4">

                                <div className="generic_content active clearfix">


                                    <div className="generic_head_price clearfix">


                                        <div className="generic_head_content clearfix">


                                            <div className="head_bg"></div>
                                            <div className="head">
                                                <span>Mascotas</span>
                                            </div>


                                        </div>

                                        <div className="generic_price_tag clearfix">
                                            <span className="price">
                                                <span className="sign">$</span>
                                                <span className="currency">9.000</span>
                                                <span className="cent">.00</span>
                                                <span className="month">/MES</span>
                                            </span>
                                        </div>


                                    </div>



                                    <div className="generic_feature_list">
                                        <ul>
                                            <li><i className="bi bi-check2-circle"> </i>Homenaje para mascotas (gatos y perros menores de 10 años)</li>
                                            <li><i className="bi bi-check2-circle"> </i>Velación, cremación y retorno de cenizas</li>
                                        </ul>
                                    </div>



                                    <div className="generic_price_btn clearfix">
                                        <a className="" href="https://wa.link/knj1ke">Info <i className="bi bi-arrow-right-short"></i></a>
                                    </div>


                                </div>


                            </div>

                            <div className="col-md-4">


                                <div className="generic_content clearfix">


                                    <div className="generic_head_price clearfix">


                                        <div className="generic_head_content clearfix">


                                            <div className="head_bg"></div>
                                            <div className="head">
                                                <span>Beneficios adicionales</span>
                                            </div>


                                        </div>

                                        <div className="generic_price_tag clearfix">
                                            <span className="price">
                                            </span>
                                        </div>


                                    </div>

                                    <div className="generic_feature_list">
                                        <ul>
                                            <li><i className="bi bi-check2-circle"> </i>Asistente familiar Equipo de expertos para acompañamiento en el duelo. (Presencial en Bogotá – Virtual otras ciudades)</li>
                                            <li><i className="bi bi-check2-circle"> </i>Repatriación para el grupo familiar (hasta 30 SMMLV)</li>
                                            <li><i className="bi bi-check2-circle"> </i>Seguro de vida por fallecimiento del titular (Hasta 3 SMMLV – revisar condiciones y restricciones según el plan)</li>
                                            <li><i className="bi bi-check2-circle"> </i>Cubrimiento de exhumación (Osario y cenizario - revisar condiciones y restricciones según el plan) </li>
                                            <li><i className="bi bi-check2-circle"> </i>Conductor elegido para tramites y disposición del proceso funerario por dos días. (en la ciudad de Bogotá)</li></ul>
                                    </div>

                                    <div className="generic_price_btn clearfix">
                                        <a className="" href="https://wa.link/knj1ke">Info <i className="bi bi-arrow-right-short"></i></a>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </section>

            </div>
        </body>
    )
}

export default ServicesComp